import { getFormDefinition, createFormDataFromItem, createItemFromFormData } from './form';
import { entityToStore } from '@/utils/store';
import baseCrudMixin from '@/mixins/crud';
import { validateUpperCase, validateLowerCase, validateDigit, validateNonAlphanumeric } from '@/utils/validate';

export default {
  mixins: [baseCrudMixin],
  computed: {
    definition() {
      return this.createDefinition();
    },
    entityStore() {
      return entityToStore(this.entity);
    }
  },
  data: () => ({
    entity: 'ExternalApiUser',
    returnTo: 'ExternalApiUsers'
  }),
  created() {
    this.$store.dispatch('getPasswordPolicy');
    const domainID = this.$store.getters.domainID;
    this.$store.dispatch('role/getItems', { domainID: domainID });
  },
  methods: {
    createDefinition() {
      return getFormDefinition(this);
    },
    passwordCompliesWithPolicy(value) {
      const passwordPolicy = this.$store.state.authentication.passwordPolicy;
      return {
        alphanumeric: !passwordPolicy.requireNonAlphanumeric || validateNonAlphanumeric(value),
        digit: !passwordPolicy.requireDigit || validateDigit(value),
        isFromDatabase: passwordPolicy.isFromDatabase,
        length: value.length >= passwordPolicy.minimumLength,
        lowercase: !passwordPolicy.requireLowercase || validateLowerCase(value),
        uppercase: !passwordPolicy.requireUppercase || validateUpperCase(value)
      };
    },
    createFormDataFromItem,
    createItemFromFormData
  }
};
