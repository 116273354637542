import MDinput from '@/components/MDinput';
import { validatorRequire, validatorRequireMultipleSelect } from '@/utils/validators';
import i18n from '@/i18n';
import Select from '../../../../components/DSE/Select';

const validateNewPassword = vue => (rule, value, callback) => {
  if (value !== '' && value != null) {
    const isValid = vue.passwordCompliesWithPolicy(value);
    if (Object.values(isValid).some(e => !e)) {
      callback(new Error(i18n.t('validation.error.passwordComplexity')));
    } else {
      callback();
    }
  } else {
    callback();
  }
};

export function getFormDefinition(vue) {
  return {
    id: {
      type: null,
      defaultValue: undefined
    },
    code: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 255,
        required: true,
        caption: 'login.code'
      },
      rules: [
        {
          validator: validatorRequire
        }
      ]
    },
    description: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 255
      }
    },
    roles: {
      type: Select,
      defaultValue: [],
      props: {
        caption: 'user.roles',
        valueField: 'id',
        labelField: 'name',
        multiple: true,
        loading: vue.$store.getters['role/isLoading'],
        options: vue.$store.getters['role/loadedItems'],
        required: true
      },
      rules: [
        {
          validator: validatorRequireMultipleSelect
        }
      ]
    },
    password: {
      type: MDinput,
      defaultValue: '',
      props: {
        type: 'password',
        maxlength: 64
      },
      dynamicProps: form => ({
        caption: vue.isEdit ? 'user.newPassword' : 'common.password'
      }),
      dynamicRules: form => {
        const rules = [{ validator: validateNewPassword(vue) }];
        if (!vue.isEdit) {
          rules.push({ validator: validatorRequire });
        }
        return rules;
      }
    }
  };
}

export function createItemFromFormData(vue, form) {
  const user = {
    ...form,
    domainID: vue.$store.getters.domainID,
    roles: []
  };
  if (form.id) {
    user['id'] = form.id;
  }
  form.roles.forEach(roleID => {
    const role = vue.$store.getters['role/getLoadedItemByID'](roleID);
    user.roles.push(role);
  });
  return user;
}

export function createFormDataFromItem(vue, user) {
  const form = {
    ...user
  };
  form.roles = [];
  user.roles.forEach(role => {
    form.roles.push(role.id);
  });
  return form;
}
